import Blocks from '@component/Blocks';
import PageHeader from '@component/PageHeader';
import RelatedEntries from '@component/RelatedEntries';
import Txt from '@componentPrimitives/Txt';
import LayoutDefault from '@layout/LayoutDefault';
import { useEvent } from '@lib/hooks';
import React from 'react';
import { EventLayoutSidebar } from './EventLayout.Sidebar';

const EventLayout = () => {
  const event = useEvent();

  return (
    <LayoutDefault
      slots={{
        header: <PageHeader />,
        body: [
          <React.Fragment key={'intro'}>
            {!event?.blocks?.length ? (
              <Txt key="summary" html variant="lede">
                {event.entrySummary}
              </Txt>
            ) : null}
          </React.Fragment>,
          <Blocks key="blocks" disableContainers />,
        ],
        footer: <RelatedEntries key="relatedEntries" />,
        sidebar: <EventLayoutSidebar />,
      }}
    />
  );
};

export default EventLayout;
