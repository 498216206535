// import { ShareLinks } from '@component/SocialLinks';
import Flex, { FlexProps } from '@componentPrimitives/Flex';
import Txt from '@primitive/Txt';
import { isArray, isString } from 'lodash';
import React from 'react';

export type EntryMetaProps = FlexProps<
  'div',
  {
    slotAfter?: React.ReactNode;
    metaValues: { label: string; value: React.ReactNode | string[] }[];
  }
>;

const EntryMeta = ({ metaValues, slotAfter, cx, ...props }: EntryMetaProps) => {
  metaValues = metaValues.filter(({ value }) =>
    value ? (isArray(value) ? value.length : value) : false
  );

  console.log('metaValues', metaValues);

  return (
    <Flex
      rounded
      colorSet="secondary"
      paper
      spacing="xs"
      alignItems="start"
      cx={{ p: 'md', mB: 'xl', ...cx }}
      {...props}>
      {metaValues.map(({ label, value }) =>
        !value ? null : (
          <div key={label}>
            <Txt variant="bodyAlt" as="h5" bold cx={{ mB: 'none' }}>
              {`${label}:`}
            </Txt>
            {(isArray(value) ? value : [value]).map((v, i) =>
              v ? (
                <React.Fragment key={i}>
                  {isString(v) ? (
                    <Txt variant="body" cx={{ mT: 'none', mB: 'none' }}>
                      {v}
                    </Txt>
                  ) : (
                    v
                  )}
                </React.Fragment>
              ) : null
            )}
          </div>
        )
      )}
      {slotAfter}
    </Flex>
  );
};

export default EntryMeta;
